/* global webauthnUrl webauthnData */

import {
    get,
    parseRequestOptionsFromJSON,
} from '@github/webauthn-json/browser-ponyfill';

$(window).on('sessions:loaded', () => {
    const profileImgSrc = localStorage?.getItem('profile_img_src');
    const originalProfileImg = $('#profile_img').html();
    const profileEmail = localStorage?.getItem('profile_email');
    const profileName = localStorage?.getItem('profile_name');

    if (profileEmail) {
        $('#user_email').val(profileEmail).parent().hide();
        $('#profile_email').html(profileEmail).show();
        $('#profile_name').html(profileName).show();
        $('#change_user').on('click', ({ currentTarget }) => {
            $(currentTarget).hide();
            $('#profile_name').hide();
            $('#profile_email').hide();
            $('#user_email').empty().parent().show();
            $('#profile_img').html(originalProfileImg);
            return false;
        }).show();
        if (profileImgSrc) { $('#profile_img').html($('<img/>', { class: 'avatar-image big', src: profileImgSrc })); }
    }
    $('#modal_login').modal({
        backdrop: 'static',
        keyboard: false,
    }).modal('show');
});

$(window).on('sessions#webauthn_new:loaded', () => {
    // options for MFA
    get(parseRequestOptionsFromJSON({ publicKey: webauthnData })).then((response) => {
        // console.log(response)
        $.post(webauthnUrl, { publicKeyCredential: response.toJSON(), authenticity_token: $('meta[name=csrf-token]').attr('content') }, (data) => {
            window.location.replace(data);
        }).fail((jqXHR) => {
            // eslint-disable-next-line no-alert
            alert(jqXHR.responseText);
            window.location.reload();
        });
    });
});
