$(window).on('documents#new:loaded documents#edit:loaded documents#create:loaded documents#update:loaded', () => {
    const PlaceholderButton = (context) => {
        const { ui } = $.summernote;
        const button = ui.buttonGroup([
            ui.button({
                contents: '[&hellip;] <i class="fa fa-caret-down"/>',
                tooltip: $('#document_body').data('placeholder_tooltip'),
                data: {
                    bs_toggle: 'dropdown',
                },
            }),
            ui.dropdown({
                items: Object.keys($('#document_body').data('placeholders')),
                callback(items) {
                    $(items).find('a.dropdown-item').on('click', (e) => {
                        context.invoke('editor.insertText', $('#document_body').data('placeholders')[$(e.currentTarget).html()]);
                        e.preventDefault();
                    });
                },
            }),
        ]);
        return button.render();
    };
    $('#document_body').summernote({
        ...window.summernote_config,
        buttons: {
            placeholder: PlaceholderButton,
        },
    });
});
