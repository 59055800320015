$(window).on('invoices#index:loaded', () => {
    $('#status_quote, #status_emitted, #status_paid').on('change', ({ currentTarget }) => {
        $(currentTarget).closest('form').on('submit');
    });
    $('#download_submit').on('click', () => {
        $('#download_modal').modal('toggle');
        $('#download-alert').show();
        return true;
    });
    $('#download_modal').on('show.bs.modal', (e) => {
        $('#download_submit').val($(e.relatedTarget).data('modal-submit'));
    });
});

$(window).on('invoices#new:loaded invoices#edit:loaded invoices#create:loaded invoices#update:loaded', () => {
    $('#invoice_status').on('change', ({ currentTarget }) => {
        $('#invoice-number').prop('disabled', $(currentTarget).val() === 'quote');
    });
    $('#invoice_reference_veterinary').autocomplete({
        source: $('#invoice_reference_veterinary').data('reference-veterinaries'),
        minLength: 1,
    });
    $('#invoice_date').on('change', ({ currentTarget }) => {
        try {
            const dateEntered = new Date(this.value);
            const dateDefault = new Date($(currentTarget).data('default-value'));
            const datePrevious = new Date($(currentTarget).data('prev-value') || $(currentTarget).data('default-value'));
            if (dateEntered.getFullYear() !== datePrevious.getFullYear()) { $('#invoice-number').val(dateEntered.getFullYear() === dateDefault.getFullYear() ? $('#invoice-number').data('default-value') : ''); }
            $(currentTarget).data('prev-value', this.value);
        } catch (error) { /* empty */ }
    });
    function recalcInvoice() {
        let totalTaxable = 0;
        let totalTax = 0;
        let totalEnpav = 0;
        let total = 0;
        let totalDiscount = 0;
        let totalWithholdingTax = 0;
        const enpav = (parseFloat($('#invoice_enpav').text()) || 0.0) / 100;
        const discount = (parseFloat($('#invoice_discount').val()) || 0.0) / 100;
        const withholdingTax = $('#invoice_withholding_tax_enabled').is(':checked') ? ((parseFloat($('#invoice_withholding_tax').text()) || 0.0) / 100) : 0.0;
        $('#invoice_rows tbody tr').each((i, tr) => {
            if ($(tr).find('[id$=_destroy]').val() === '1') { return; }
            const qty = parseFloat($(tr).find('[id$=qty]').val()) || 0.0;
            const cost = parseFloat($(tr).find('[id$=cost]').val()) || 0.0;
            let tax = (parseFloat($(tr).find('[id$=tax]').val()) || 0.0) / 100;
            const costWithoutTax = (cost * qty) / (1 + tax);
            const costWithoutTaxAndEnpav = costWithoutTax / (1 + enpav);
            const taxable = costWithoutTaxAndEnpav;
            totalTaxable += taxable;
            const discountValue = taxable * discount;
            totalDiscount += discountValue;
            const enpavValue = (taxable - discountValue) * enpav;
            totalEnpav += enpavValue;
            tax *= (taxable - discountValue + enpavValue);
            totalTax += tax;
        });
        total = totalTaxable - totalDiscount + totalEnpav + totalTax;
        if (withholdingTax > 0) {
            totalWithholdingTax = (totalTaxable - totalDiscount) * withholdingTax;
            total -= totalWithholdingTax;
        }
        $('#total_taxable').text(totalTaxable.toFixed(2)).append('€');
        $('#total_discount').text(totalDiscount.toFixed(2)).append('€');
        $('#total_enpav').text(totalEnpav.toFixed(2)).append('€');
        $('#total_tax').text(totalTax.toFixed(2)).append('€');
        $('#total_withholding_tax').text(totalWithholdingTax.toFixed(2)).append('€');
        $('#invoice_total').text(total.toFixed(2)).append('€');
    }
    $('#invoice_customer_id').on('changed.bs.select', (e) => {
        const $customer = $(e.target).find('option:selected');
        $('#invoice_customer_complete_name').val($customer.text());
        $('#invoice_customer_complete_address').val($customer.data('complete-address'));
        $('#invoice_customer_vat').val($customer.data('vat'));
        $('#invoice_customer_tax_code').val($customer.data('tax-code'));
        $('#invoice_discount').val($customer.data('discount'));
        recalcInvoice();
    });
    if ($('body').data('action') === 'new') { $('#invoice_customer_id').trigger('changed.bs.select'); }
    $('#invoice_rows').on('focusin', '[id$=category]', ({ currentTarget }) => {
        if ($(currentTarget).data('ui-autocomplete')) return;
        const source = $(currentTarget).data('source');
        const tr = $(currentTarget).closest('tr');
        const serviceElement = tr.find('[id$=service]');
        $(currentTarget).autocomplete({
            source,
            select() {
                serviceElement.val(null);
            },
            change(event, ui) {
                if (serviceElement.data('ui-autocomplete')) {
                    serviceElement.autocomplete('option', 'source', `${serviceElement.data('source')}?category=${ui.item == null ? '' : ui.item.value}`);
                    tr.find('[id$=tax]').val(null);
                    tr.find('[id$=cost]').val(null);
                    recalcInvoice();
                }
            },
        });
    });
    $('#invoice_rows').on('focusin', '[id$=service]', ({ currentTarget }) => {
        if ($(currentTarget).data('ui-autocomplete')) return;
        const sourceUrl = $(currentTarget).data('source');
        const tr = $(currentTarget).closest('tr');
        const category = tr.find('[id$=category]').val();
        const source = `${sourceUrl}?category=${category}`;
        $(currentTarget).autocomplete({
            source,
            select(event, ui) {
                tr.find('[id$=tax]').val(ui.item.tax);
                tr.find('[id$=cost]').val(ui.item.rate);
                recalcInvoice();
            },
        });
    });
    $('#invoice_rows').on('input propertychange paste', '[id$=qty], [id$=cost], [id$=tax]', () => {
        recalcInvoice();
    });
    $('#invoice_discount').on('input propertychange paste', () => {
        recalcInvoice();
    });
    $(document).on('cocoon:after-remove', () => {
        recalcInvoice();
    });
    $('#invoice_withholding_tax_enabled').on('change', () => {
        recalcInvoice();
    });
});
