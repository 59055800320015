$(window).on('biochemical_exams#new:loaded biochemical_exams#edit:loaded biochemical_exams#create:loaded biochemical_exams#update:loaded', () => {
    $('#biochemical_exam_albumin').on('change', ({ currentTarget }) => {
        const albumin = parseFloat($(currentTarget).val());
        const globulin = parseFloat($('#biochemical_exam_globulin').val());
        const ag = parseFloat($('#biochemical_exam_ag').val());
        if (!Number.isNaN(albumin)) {
            if (!Number.isNaN(globulin)) {
                $('#biochemical_exam_ag').val((albumin / globulin).toFixed(2));
            } else if (!Number.isNaN(ag)) {
                $('#biochemical_exam_globulin').val((albumin / ag).toFixed(2));
            }
        }
    });
    $('#biochemical_exam_globulin').on('change', ({ currentTarget }) => {
        const albumin = parseFloat($('#biochemical_exam_albumin').val());
        const globulin = parseFloat($(currentTarget).val());
        const ag = parseFloat($('#biochemical_exam_ag').val());
        if (!Number.isNaN(globulin)) {
            if (!Number.isNaN(albumin)) {
                $('#biochemical_exam_ag').val((albumin / globulin).toFixed(2));
            } else if (!Number.isNaN(ag)) {
                $('#biochemical_exam_albumin').val((globulin * ag).toFixed(2));
            }
        }
    });
    $('#biochemical_exam_ag').on('change', ({ currentTarget }) => {
        const albumin = parseFloat($('#biochemical_exam_albumin').val());
        const globulin = parseFloat($('#biochemical_exam_globulin').val());
        const ag = parseFloat($(currentTarget).val());
        if (!Number.isNaN(ag)) {
            if (!Number.isNaN(albumin)) {
                $('#biochemical_exam_globulin').val((albumin / ag).toFixed(2));
            } else if (!Number.isNaN(globulin)) {
                $('#biochemical_exam_albumin').val((globulin * ag).toFixed(2));
            }
        }
    });
});
