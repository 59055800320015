/* global salesChartData clinicFileData examsChartData customersMapData invoicesPaymentData google */

import Chart from 'chart.js/auto';
import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import enLocale from '@fullcalendar/core/locales/en-gb';
import itLocale from '@fullcalendar/core/locales/it';
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import isMobile from 'ismobilejs';
import strings from '../strings.js.erb';


// di base userebbe le Bootstrap icons
bootstrap5Plugin.themeClasses.bootstrap5.prototype.baseIconClass = 'fa';
bootstrap5Plugin.themeClasses.bootstrap5.prototype.iconOverridePrefix = 'fa-';
bootstrap5Plugin.themeClasses.bootstrap5.prototype.iconClasses = {
    close: 'fa-times',
    prev: 'fa-chevron-left',
    next: 'fa-chevron-right',
    prevYear: 'fa-angle-double-left',
    nextYear: 'fa-angle-double-right',
};
bootstrap5Plugin.themeClasses.bootstrap5.prototype.rtlIconClasses = {
    prev: 'fa-chevron-right',
    next: 'fa-chevron-left',
    prevYear: 'fa-angle-double-right',
    nextYear: 'fa-angle-double-left',
};

$(window).on('home#index:loaded', () => {
    /// //////////////
    // Calendar
    /// //////////////

    const toDatetimeLocal = (date) => {
        const ten = (i) => (i < 10 ? '0' : '') + i;
        const YYYY = date.getFullYear();
        const MM = ten(date.getMonth() + 1);
        const DD = ten(date.getDate());
        const HH = ten(date.getHours());
        const II = ten(date.getMinutes());
        const SS = ten(date.getSeconds());
        return `${YYYY}-${MM}-${DD}T${HH}:${II}:${SS}`;
    };

    function fillPets(customerId, customerName, petId) {
        if (customerId || customerName) {
            const params = customerId ? { customer_id: customerId } : { customer: customerName };
            $.getJSON($('#calendar_event_pet_id').data('source'), params, (data) => {
                // alert(JSON.stringify(data));
                $('#calendar_event_pet_id').empty().append($('<option>'));
                $.each(data, (i, pet) => {
                    $('#calendar_event_pet_id').append($('<option>').val(pet.id).text(pet.name));
                });
                if (petId) { $('#calendar_event_pet_id').val(petId).trigger('change'); }
            });
        } else { $('#calendar_event_pet_id').empty().append($('<option>')); }
    }

    const calendar = new Calendar($('#calendar')[0], {
        plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin, bootstrap5Plugin],
        locales: [itLocale, enLocale],
        locale: $('#calendar').data('locale'),
        themeSystem: 'bootstrap5',
        height: isMobile.any ? 650 : null,
        allDaySlot: false,
        slotDuration: '00:15:00',
        scrollTime: '08:00:00',
        businessHours: [{
            daysOfWeek: [1, 2, 3, 4],
            startTime: '09:30',
            endTime: '19:30',
        }, {
            daysOfWeek: [5],
            startTime: '09:30',
            endTime: '17:00',
        }, {
            daysOfWeek: [6],
            startTime: '09:00',
            endTime: '12:30',
        }],
        headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay listMonth',
        },
        navLinks: true,
        initialView: isMobile.any ? 'timeGridWeek' : 'timeGridWeek',
        selectable: true,
        selectMirror: true,
        select(selectionInfo) {
            if (selectionInfo.start.getDay() !== selectionInfo.end.getDay()) {
                calendar.unselect();
                return;
            }
            // Nuovo evento
            $('#modal_calendar').clear_previous_errors();
            const userIdDefault = $('#calendar_event_user_id').data('default') || $('#calendar_event_user_id').data('current');
            const customerDefault = { id: $('#calendar_event_customer_id').data('default'), name: $('#calendar_event_customer').data('default') };
            const petIdDefault = $('#calendar_event_pet_id').data('default');
            $('#calendar_event_start').val(toDatetimeLocal(selectionInfo.start));
            $('#calendar_event_end').val(toDatetimeLocal(selectionInfo.end).slice(-8));
            $('#calendar_event_title').val('');
            $('#calendar_event_id').val('');
            $('#calendar_event_user_id').val(userIdDefault).trigger('change');
            $('#calendar_event_description').val('');
            $('#calendar_event_customer_id').val(customerDefault.id);
            $('#calendar_event_customer').val(customerDefault.name);
            fillPets(customerDefault.id, null, petIdDefault);
            $('#modal_calendar_submit span').text(strings.calendar.new);
            $('#modal_calendar_delete').hide();
            $('#modal_calendar').modal('show');
        },
        // editable: true,
        eventSources: [{
            url: $('#calendar').data('source'),
            extraParams: {},
        }],
        eventDidMount(info) {
            $(info.el).prop('title', info.event.extendedProps.description).tooltip();
            if (info.event.extendedProps.customer_name) {
                $(info.el).find('.fc-title').append(`<br/><small><em>${info.event.extendedProps.customer_name}</em></small>`);
                $(info.el).find('.fc-list-item-title').append(`&nbsp;&nbsp;&raquo;<small><em>${info.event.extendedProps.customer_name}</em></small>`);
            }
            const view = calendar.view.constructor.name;
            if (info.event.extendedProps.pet_id && (view === 'timeGridWeek' || view === 'timeGridDay') && info.event.extendedProps.pet_avatar_present) {
                const div = view === 'timeGridDay' ? $('<div style="position:absolute;top:5px;right:5px">') : $('<div>');
                div.load(`${$('#pet_avatar').data('source').replace(':id', info.event.extendedProps.pet_id)}?size=thumb`, () => {
                    div.appendTo($(info.el));
                });
            }
        },
        eventClick(eventClickInfo) {
            // Mostra evento
            $('#modal_calendar').clear_previous_errors();
            $('#calendar_event_start').val(toDatetimeLocal(eventClickInfo.event.start));
            $('#calendar_event_end').val(toDatetimeLocal(eventClickInfo.event.end).slice(-8));
            $('#calendar_event_title').val(eventClickInfo.event.title);
            $('#calendar_event_id').val(eventClickInfo.event.id);
            $('#calendar_event_user_id').val(eventClickInfo.event.extendedProps.user_id).trigger('change');
            $('#calendar_event_description').val(eventClickInfo.event.extendedProps.description);
            $('#calendar_event_customer_id').val(eventClickInfo.event.extendedProps.customer_id);
            $('#calendar_event_customer').val(eventClickInfo.event.extendedProps.customer_name);
            fillPets(
                eventClickInfo.event.extendedProps.customer_id,
                eventClickInfo.event.extendedProps.customer_name,
                eventClickInfo.event.extendedProps.pet_id,
            );
            $('#modal_calendar_submit span').text(strings.calendar.edit);
            $('#modal_calendar_delete').show();
            $('#modal_calendar').modal('show');
        },
    });
    calendar.render();

    if ($('#calendar').data('date')) {
        calendar.gotoDate(new Date($('#calendar').data('date')));
    }
    $('#calendar_event_title').on('input', ({ currentTarget }) => {
        $('#modal_calendar_submit').prop('disabled', ($(currentTarget).val().length === 0));
    });
    $('#modal_calendar_delete').on('click', ({ currentTarget }) => {
        (async () => {
            if (await window.myConfirm($(currentTarget).data('calendar-confirm'))) {
                $.ajax({
                    type: 'DELETE',
                    url: $('#calendar').data('source'),
                    beforeSend(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')); },
                    data: {
                        id: $('#calendar_event_id').val(),
                        authenticity_token: $('#calendar_event_form [name=authenticity_token]').val(),
                    },
                }).done(() => {
                    calendar.refetchEvents();
                    $('#modal_calendar').modal('hide');
                });
            }
        })();
        return false;
    });
    $('#calendar_event_customer').autocomplete({
        source: $('#calendar_event_customer').data('source'),
        appendTo: '#modal_calendar',
        minLength: 2,
        change(e, ui) {
            if (!(ui.item)) {
                e.target.value = '';
                $('#calendar_event_customer_id').val('');
            }
        },
        select(event, ui) {
            $('#calendar_event_customer_id').val(ui.item.id);
            fillPets(ui.item.id, this.value);
        },
    });
    $('#calendar_event_user_id').on('change', ({ currentTarget }) => {
        const color = $('#calendar_event_user_id').children(`option[value='${$(currentTarget).val()}']`).data('color');
        $('#user_color').css('color', color);
    });
    $('#calendar_event_pet_id').on('change', ({ currentTarget }) => {
        if ($(currentTarget).val()) {
            $('#pet_avatar').load(
                `${$('#pet_avatar').data('source').replace(':id', $(currentTarget).val())}?size=thumb`,
                () => $('#pet_avatar').fadeIn(),
            ).hide();
        } else {
            $('#pet_avatar').empty();
        }
    });
    $('#calendar_event_form').on('ajax:success', () => {
        calendar.refetchEvents();
        $('#modal_calendar').modal('hide');
        $('div.alert.show').alert('close');
        $('#calendar_event_user_id, #calendar_event_customer_id, #calendar_event_pet_id, #calendar_event_customer').data('default', null);
    });

    $('#calendar_user_id').on('change', ({ currentTarget }) => {
        const eventsSource = calendar.getEventSources()[0];
        const userIds = $(currentTarget).children('option:selected').map((s) => $(s).val()).get();
        eventsSource.internalEventSource.meta.extraParams = { userIds };
        eventsSource.refetch();
    });

    /// //////////////
    // Messages
    /// //////////////

    $('#messages-container').on('ajax:before', "[data-remote='true']", () => {
        $('#messages-container').hide();
    }).on('ajax:success', "[data-remote='true']", (_event, data) => {
        $('#messages-container').html(data).show();
    }).on('ajax:error', "[data-remote='true']", (_event, xhr) => {
        const error = xhr.responseText ? JSON.parse(xhr.responseText) : strings.error;
        $('#messages-container').html(error).show();
    });

    $('#messages-container').on('click', "[data-bs-toggle='collapse']", ({ currentTarget }) => {
        $(currentTarget).hide();
        $(currentTarget).closest('.dropdown').removeClass('open');
        // $(currentTarget).closest(".row").next(".message-form").slideDown();
        return true;
    });
});

$(window).on('home#charts:loaded', () => {
    /// //////////////
    // Sales Chart
    /// //////////////

    const datasetsRowsCategories = []; const
        datasetsReferenceVeterinaries = [];
    let { year } = salesChartData;
    let backgroundColors = [
        'rgba(255, 99, 132, 0.5)',
        'rgba(54, 162, 235, 0.5)',
        'rgba(255, 206, 86, 0.5)',
        'rgba(75, 192, 192, 0.5)',
        'rgba(153, 102, 255, 0.5)',
        'rgba(255, 159, 64, 0.5)',
        'rgba(151, 187, 205, 0.5)',
        'rgba(220, 220, 220, 0.5)',
        'rgba(247, 70, 74, 0.5)',
        'rgba(70, 191, 189, 0.5)',
        'rgba(253, 180, 92, 0.5)',
        'rgba(77, 83, 96, 0.5)',
    ];
    let borderColors = [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(151, 187, 205, 1)',
        'rgba(220, 220, 220, 1)',
        'rgba(247, 70, 74, 1)',
        'rgba(70, 191, 189, 1)',
        'rgba(253, 180, 92, 1)',
    ];
    if (!salesChartData.invoices[year].every((el) => el <= 0)) {
        const datasetInvoices = {
            label: salesChartData.labels[year],
            type: 'line',
            data: salesChartData.invoices[year],
            fill: false,
            lineTension: 0.2,
            borderColor: borderColors[0],
        };
        datasetsRowsCategories.push(datasetInvoices);
        datasetsReferenceVeterinaries.push(datasetInvoices);
    }
    let counter = 1;
    if (salesChartData.invoice_rows_categories[year]) {
        Object.entries(salesChartData.invoice_rows_categories[year]).forEach(([category, months]) => {
            datasetsRowsCategories.push({
                label: `${category} ${year}`,
                custom: { year, category },
                stack: '0',
                data: months,
                backgroundColor: backgroundColors[counter],
                borderColor: borderColors[counter],
                borderWidth: 1,
            });
            counter++;
        });
    }
    counter = 1;
    if (salesChartData.invoices_reference_veterinaries[year]) {
        Object.entries(salesChartData.invoices_reference_veterinaries[year]).forEach(([refVet, months]) => {
            datasetsReferenceVeterinaries.push({
                label: `${refVet} ${year}`,
                custom: { year, reference_veterinary: refVet },
                stack: '0',
                data: months,
                backgroundColor: backgroundColors[counter],
                borderColor: borderColors[counter],
                borderWidth: 1,
            });
            counter++;
        });
    }
    year -= 1;
    backgroundColors = [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(151,187,205,0.2)',
        'rgba(220,220,220,0.2)',
        'rgba(247,70,74,0.2)',
        'rgba(70,191,189,0.2)',
        'rgba(253,180,92,0.2)',
        'rgba(77,83,96,0.2)',
    ];
    borderColors = [
        'rgba(255,99,132, 0.4)',
        'rgba(54, 162, 235, 0.4)',
        'rgba(255, 206, 86, 0.4)',
        'rgba(75, 192, 192, 0.4)',
        'rgba(153, 102, 255, 0.4)',
        'rgba(255, 159, 64, 0.4)',
        'rgba(151,187,205,0.4)',
        'rgba(220,220,220,0.4)',
        'rgba(247,70,74,0.4)',
        'rgba(70,191,189,0.4)',
        'rgba(253,180,92,0.4)',
    ];
    if (!salesChartData.invoices[year].every((el) => el <= 0)) {
        const datasetInvoices = {
            label: salesChartData.labels[year],
            type: 'line',
            yAxisID: 'B',
            data: salesChartData.invoices[year],
            fill: false,
            lineTension: 0.2,
            borderColor: borderColors[0],
        };
        datasetsRowsCategories.push(datasetInvoices);
        datasetsReferenceVeterinaries.push(datasetInvoices);
    }
    counter = 1;
    if (salesChartData.invoice_rows_categories[year]) {
        Object.entries(salesChartData.invoice_rows_categories[year]).forEach(([category, months]) => {
            datasetsRowsCategories.push({
                label: `${category} ${year}`,
                custom: { year, category },
                stack: '1',
                data: months,
                backgroundColor: backgroundColors[counter],
                borderColor: borderColors[counter],
                borderWidth: 1,
            });
            counter++;
        });
    }
    counter = 1;
    if (salesChartData.invoices_reference_veterinaries[year]) {
        Object.entries(salesChartData.invoices_reference_veterinaries[year]).forEach(([refVet, months]) => {
            datasetsReferenceVeterinaries.push({
                label: `${refVet} ${year}`,
                custom: { year, refVet },
                stack: '1',
                data: months,
                backgroundColor: backgroundColors[counter],
                borderColor: borderColors[counter],
                borderWidth: 1,
            });
            counter++;
        });
    }
    const salesChart = new Chart($('#sales_chart')[0], {
        type: 'bar',
        data: {
            labels: salesChartData.labels.months,
            datasets: datasetsRowsCategories,
        },
        options: {
            responsive: true,
            maintainAspectRatio: true,
            aspectRatio: isMobile.any ? 0.7 : 2,
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                        suggestedMin: 0,
                        min: 0,
                        callback(value) {
                            return `${Number(value.toFixed(2))}€`;
                        },
                    },
                },
                B: {
                    display: false,
                    axis: 'y',
                    ticks: {
                        beginAtZero: true,
                        suggestedMin: 0,
                        min: 0,
                        callback(value) {
                            return `${Number(value.toFixed(2))}€`;
                        },
                    },
                },
            },
            tooltips: {
                callbacks: {
                    label(tooltipItems, data) {
                        let text = `${data.datasets[tooltipItems.datasetIndex].label}: ${Number(tooltipItems.yLabel.toFixed(2))} €`;
                        const c = data.datasets[tooltipItems.datasetIndex].custom;
                        if (c && c.category) text += ` (${salesChartData.invoice_rows_categories_qty[c.year][c.category][tooltipItems.index]})`;
                        else if (c && c.reference_veterinary) text += ` (${salesChartData.invoices_reference_veterinaries_qty[c.year][c.reference_veterinary][tooltipItems.index]})`;
                        return text;
                    },
                },
            },
        },
    });
    // salesChart.config.options.scales.yAxes[1].ticks.max = salesChart.scales.A.max;
    salesChart.update();
    $('#invoice_sales').on('change', ({ currentTarget }) => {
        salesChart.data.datasets = $(currentTarget).val() === 'category' ? datasetsRowsCategories : datasetsReferenceVeterinaries;
        salesChart.update();
    });

    /// //////////////
    // Invoices Payment Chart
    /// //////////////

    const invoicesPaymentChart = new Chart($('#invoices_payment')[0], {
        type: 'pie',
        data: {
            labels: invoicesPaymentData.labels,
            datasets: [{
                data: [],
                backgroundColor: [
                    'rgba(151,187,205,0.8)',
                    'rgba(247,70,74,0.8)',
                    'rgba(70,191,189,0.8)',
                    'rgba(253,180,92,0.8)',
                    'rgba(153, 102, 255, 0.8)',
                    'rgba(54, 162, 235, 0.8)',
                ],
                hoverBackgroundColor: [
                    'rgba(151,187,205,1)',
                    'rgba(247,70,74,1)',
                    'rgba(70,191,189,1)',
                    'rgba(253,180,92,1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(54, 162, 235, 0.8)',
                ],
            }],
        },
        options: {
            maintainAspectRatio: false,
            tooltips: {
                callbacks: {
                    label(item, data) {
                        return `${data.labels[item.index]}: ${Number(data.datasets[item.datasetIndex].data[item.index].toFixed(2))} €`;
                    },
                },
            },
        },
    });
    $('#invoices_payments_form').on('ajax:success', (_event, data) => {
        // alert(JSON.stringify(data));
        invoicesPaymentChart.data.datasets[0].data = data;
        invoicesPaymentChart.update();
    });
    const submit = new Event('submit', { bubbles: true });
    $('#invoice_date_3i, #invoice_date_2i, #invoice_date_1i').on('change', () => $('#invoices_payments_form')[0].dispatchEvent(submit));
    $('#invoices_payments_form')[0].dispatchEvent(submit);

    /// //////////////
    // Clinic File Chart
    /// //////////////

    // eslint-disable-next-line no-unused-vars
    const clinicFileChart = new Chart($('#clinic_file')[0], {
        type: 'doughnut',
        data: {
            labels: clinicFileData.labels,
            datasets: [{
                data: clinicFileData.data[clinicFileData.year],
                label: clinicFileData.year,
                backgroundColor: [
                    'rgba(151,187,205,0.8)',
                    'rgba(247,70,74,0.8)',
                    'rgba(70,191,189,0.8)',
                    'rgba(253,180,92,0.8)',
                    'rgba(153, 102, 255, 0.8)',
                ],
                hoverBackgroundColor: [
                    'rgba(151,187,205,1)',
                    'rgba(247,70,74,1)',
                    'rgba(70,191,189,1)',
                    'rgba(253,180,92,1)',
                    'rgba(153, 102, 255, 1)',
                ],
            }, {
                data: clinicFileData.data[clinicFileData.year - 1],
                label: clinicFileData.year - 1,
                backgroundColor: [
                    'rgba(151,187,205,0.4)',
                    'rgba(247,70,74,0.4)',
                    'rgba(70,191,189,0.4)',
                    'rgba(253,180,92,0.4)',
                    'rgba(153, 102, 255, 0.4)',
                ],
                hoverBackgroundColor: [
                    'rgba(151,187,205,0.6)',
                    'rgba(247,70,74,0.6)',
                    'rgba(70,191,189,0.6)',
                    'rgba(253,180,92,0.6)',
                    'rgba(153, 102, 255, 0.6)',
                ],
            }],
        },
        options: {
            maintainAspectRatio: false,
            tooltips: {
                callbacks: {
                    label(item, data) {
                        return `${data.labels[item.index]} ${data.datasets[item.datasetIndex].label}: ${data.datasets[item.datasetIndex].data[item.index]}`;
                    },
                },
            },
        },
    });

    /// //////////////
    // Exams Chart
    /// //////////////

    // eslint-disable-next-line no-unused-vars
    const examsChart = new Chart($('#exams_chart'), {
        type: 'radar',
        data: {
            labels: examsChartData.labels,
            datasets: [{
                label: examsChartData.year,
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)',
                pointBackgroundColor: 'rgba(255,99,132,1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(255,99,132,1)',
                data: examsChartData.data[examsChartData.year],
            }, {
                label: examsChartData.year - 1,
                backgroundColor: 'rgba(179,181,198,0.2)',
                borderColor: 'rgba(179,181,198,1)',
                pointBackgroundColor: 'rgba(179,181,198,1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(179,181,198,1)',
                data: examsChartData.data[examsChartData.year - 1],
            }],
        },
        options: {
            maintainAspectRatio: false,
            scale: {
                ticks: {
                    beginAtZero: true,
                    suggestedMin: 0,
                    min: 0,
                    callback(value) {
                        return parseInt(value, 10);
                    },
                },
            },
        },
    });

    /// //////////////
    // Services Chart
    /// //////////////

    const data = []; const
        labels = [];
    if (salesChartData.invoice_rows_categories_qty[salesChartData.year]) {
        Object.entries(salesChartData.invoice_rows_categories_qty[salesChartData.year]).forEach(([category, months]) => {
            labels.push(category);
            data.push(months.reduce((sum, n) => sum + n, 0));
        });
    }
    // eslint-disable-next-line no-unused-vars
    const servicesChart = new Chart($('#services_chart')[0], {
        type: 'polarArea',
        data: {
            labels,
            datasets: [{
                label: '<%= @year %>',
                backgroundColor: [
                    'rgba(54, 162, 235, 0.8)',
                    'rgba(255, 206, 86, 0.8)',
                    'rgba(75, 192, 192, 0.8)',
                    'rgba(153, 102, 255, 0.8)',
                    'rgba(255, 159, 64, 0.8)',
                    'rgba(151,187,205, 0.8)',
                    'rgba(220,220,220, 0.8)',
                    'rgba(247,70,74, 0.8)',
                    'rgba(70,191,189, 0.8)',
                    'rgba(253,180,92, 0.8)',
                    'rgba(77,83,96, 0.8)',
                ],
                data,
            }],
        },
        options: {
            maintainAspectRatio: false,
        },
    });

    /// //////////////
    // Map
    /// //////////////

    async function initMap() {
        const { Map } = await google.maps.importLibrary('maps');
        const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');
        const { LatLngBounds } = await google.maps.importLibrary('core');
        const map = new Map($('#customers-map')[0], {
            zoom: 8,
            center: { lat: 44.8, lng: 11 },
            fullscreenControl: true,
            mapId: 'customers',
        });
        // var geocoder = new google.maps.Geocoder();
        const bounds = new LatLngBounds();
        const markers = [];
        customersMapData.customers.forEach((c) => {
            const iconImage = document.createElement('img');
            iconImage.src = `https://maps.google.com/mapfiles/kml/shapes/${c.sex === 'female' ? 'woman' : 'man'}.png`;
            iconImage.height = 32;
            iconImage.width = 32;
            const marker = new AdvancedMarkerElement({
                map,
                position: c.pos,
                title: c.name,
                content: iconImage,
            });
            bounds.extend(c.pos);
            markers.push(marker);
        });
        // eslint-disable-next-line no-unused-vars
        const markerCluster = new MarkerClusterer({
            map,
            markers,
            algorithm: new SuperClusterAlgorithm({ maxZoom: 15, radius: 60 }),
            renderer: {
                render({ count, position }, stats) {
                    // const clusterImage = document.createElement('img');
                    const clusterImage = Object.assign(document.createElement('div'), {
                        style: `
                          background-size: cover;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          text-align: center;
                          color: white;
                          padding-top: 15px;
                        `,
                        innerText: count,
                    });
                    // change color if this cluster has more markers than the mean cluster
                    if (count < Math.max(10, stats.clusters.markers.mean)) {
                        Object.assign(clusterImage.style, { 'background-image': 'url(/markerclusterer/people35.png)', width: '35px', height: '35px' });
                    } else if (count < Math.max(100, stats.clusters.markers.mean)) {
                        Object.assign(clusterImage.style, { 'background-image': 'url(/markerclusterer/people45.png)', width: '45px', height: '45px' });
                    } else {
                        Object.assign(clusterImage.style, { 'background-image': 'url(/markerclusterer/people55.png)', width: '55px', height: '55px' });
                    }
                    return new AdvancedMarkerElement({
                        map,
                        position,
                        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                        content: clusterImage,
                    });
                },
            },
        });
        if (!bounds.isEmpty()) { map.fitBounds(bounds); }
    }
    initMap();
});
