export default {
	confirm: {
		title: 'Sei sicuro?',
  		commit: 'Conferma',
  		cancel: 'Annulla',
	},
  	error: 'Errore',
	calendar: {
		new: 'Crea appuntamento',
		edit: 'Modifica',
	},
	introjsOptions: {
		
		nextLabel: 'Succ. &rarr;',
		prevLabel: '&larr; Prec.',
		skipLabel: 'x',
		doneLabel: 'Fine'
		
	},
	pets: {
		nfc: {
			success: 'Tag scritto correttamente',
		}
	}
};
