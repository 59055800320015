import strings from '../strings.js.erb';

$(window).on('pets#new:loaded pets#edit:loaded pets#create:loaded pets#update:loaded', () => {
    $('#pet_species_id').on('change', ({ currentTarget }) => {
        switch ($(currentTarget).find(':selected').data('special')) {
            case 'dog':
                $('#pet_breed').autocomplete({ source: $('#pet_breed').data('source-dog') });
                break;
            case 'cat':
                $('#pet_breed').autocomplete({ source: $('#pet_breed').data('source-cat') });
                break;
            default:
                try {
                    $('#pet_breed').autocomplete('destroy');
                } catch (err) { /* empty */ }
        }
    }).trigger('change');
});

$(window).on('pets#show:loaded', () => {
    $('form.edit_pet').on('ajax:success', () => {
        $('form.edit_pet .text-success').fadeIn(() => {
            $('form.edit_pet :submit').attr('disabled', true);
            setTimeout(() => { $('form.edit_pet .text-success').fadeOut(); }, 1000);
        });
    }).on('ajax:error', ({ detail }) => {
        const xhr = detail[2];  // response, status, xhr
        const error = xhr.responseText ? JSON.parse(xhr.responseText) : strings.error;
        // eslint-disable-next-line no-alert
        alert(error);
    });
    $('#pet_notes').on('change keyup paste', () => {
        $('form.edit_pet :submit').removeAttr('disabled');
    });

    $('a[data-document]').on('click', ({ currentTarget }) => {
        $('#document_modal').load($(currentTarget).data('document'), () => {
            $('#document_modal').modal('show');
            $('#printed_document_body').summernote(window.summernote_config);
            $('button[type="submit"][value="download"]').on('click', () => {
                $('#document_modal').modal('toggle');
            });
        });
        return false;
    });

    if ('nfc' in navigator) {
        $('#write_nfc').removeClass('d-none').addClass('d-print-none');
        $('#write_nfc').on('click', ({ currentTarget }) => {
            $(currentTarget).addClass('d-none');
            $('#nfc_writing').removeClass('d-none');
            let species = '';
            switch ($('[data-nfc="species"]').data('nfc-special')) {
                case 'dog':
                    species = '🐶';
                    break;
                case 'cat':
                    species = '🐱';
                    break;
                default:
                    species = '';
            }
            let records = [{ recordType: 'text', data: `>> ${species}${$('[data-nfc="pet"]').text()}${species} <<` },
                { recordType: 'text', data: '' }, { recordType: 'text', data: $('[data-nfc="customer"]').text() }];
            if ($('[data-nfc="customer"]').data('nfc-address')) { records.push({ recordType: 'text', data: $('[data-nfc="customer"]').data('nfc-address') }); }
            if ($('[data-nfc="customer"]').data('nfc-city')) { records.push({ recordType: 'text', data: $('[data-nfc="customer"]').data('nfc-city') }); }
            if ($('[data-nfc="customer"]').data('nfc-phone')) { records.push({ recordType: 'url', data: `tel:${$('[data-nfc="customer"]').data('nfc-phone')}` }); }
            records = records.concat([{ recordType: 'text', data: '' }, { recordType: 'text', data: '' }]);
            navigator.nfc.push({
                records,
            }, {
                target: 'tag',
                timeout: 10000,
            })
                .then(() => {
                    // eslint-disable-next-line no-alert
                    alert(strings.pets.nfc.success);
                    $('#nfc_writing').addClass('d-none');
                    $('#write_nfc').removeClass('d-none');
                }).catch((error) => {
                    // eslint-disable-next-line no-alert
                    alert(error);
                    $('#nfc_writing').addClass('d-none');
                    $('#write_nfc').removeClass('d-none');
                });
            return false;
        });
    }
});
