// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Rails
// require("@rails/activestorage").start()
// require("channels")

window.$ = require('jquery');

window.jQuery = window.$;
window.Chart = require('chart.js/auto');
window.bootstrap = require('bootstrap');

require('chartjs-plugin-annotation');
require('bootstrap-colorpicker');
require('bootstrap-select');
require('bootstrap-select/js/i18n/defaults-it_IT');
require('jquery-ui/ui/widgets/autocomplete');
require('summernote/dist/summernote-bs5');
require('summernote/dist/lang/summernote-it-IT');
require('@nathanvda/cocoon');

// Customs
require('./main');

function importAll(r) {
    r.keys().forEach(r);
}
importAll(require.context('./packs', false, /\.js$/));
