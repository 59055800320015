$(window).on('services#index:loaded', () => {
    const loadModal = () => {
        $('#service_modal').find('#service_category').autocomplete({
            source: $('#service_category').data('source'),
            appendTo: '#service_modal',
            minLength: 0,
        }).on('focus', ({ currentTarget }) => {
            $(currentTarget).autocomplete('search');
        });
        $('#service_modal').modal('show');
    };
    $('body').on('click', '[data-edit]', ({ currentTarget }) => {
        $($(currentTarget).data('edit')).load($(currentTarget).href(), () => { loadModal(); });
        return false;
    });
    $('[data-new-service]').on('click', ({ currentTarget }) => {
        $('#service_modal').load($(currentTarget).data('new-service'), () => { loadModal(); });
        return false;
    });
    $('#service_modal').on('input', '#service_tax, #service_rate', () => {
        const rate = $('#service_rate').val();
        const tax = $('#service_tax').val();
        const serviceTaxable = $('label[for=service_taxable]').closest('div').children('.form-control-static');
        if (Number.isNaN(rate) || Number.isNaN(tax)) { serviceTaxable.text(''); } else {
            serviceTaxable.text(`${(rate / (1 + tax / 100)).toFixed(2)}€`);
        }
    });
});

$(window).on('services#show:loaded', () => {
    $('#search-query').on('select', ({ currentTarget }) => {
        loadRecords($('table[data-scroll]'), $(currentTarget).val());
    });
});
