$(window).on('echocardio_exams#new:loaded echocardio_exams#edit:loaded echocardio_exams#create:loaded echocardio_exams#update:loaded', () => {
    $('#echocardio_exam_pet_weight').on('change', ({ currentTarget }) => {
        const w = $(currentTarget).val();
        if (w === null || w === '') { return; }
        $('#echocardio_exam_m_ivs_dx_min').val((0.29 * (w ** 0.241) * 10).toFixed(2));
        $('#echocardio_exam_m_ivs_dx_max').val((0.59 * (w ** 0.241) * 10).toFixed(2));
        $('#echocardio_exam_m_lvid_dx_min').val((1.27 * (w ** 0.294) * 10).toFixed(2));
        $('#echocardio_exam_m_lvid_dx_max').val((1.85 * (w ** 0.294) * 10).toFixed(2));
        $('#echocardio_exam_m_lvpw_dx_min').val((0.29 * (w ** 0.232) * 10).toFixed(2));
        $('#echocardio_exam_m_lvpw_dx_max').val((0.6 * (w ** 0.232) * 10).toFixed(2));
        $('#echocardio_exam_m_ivs_sx_min').val((0.43 * (w ** 0.24) * 10).toFixed(2));
        $('#echocardio_exam_m_ivs_sx_max').val((0.79 * (w ** 0.24) * 10).toFixed(2));
        $('#echocardio_exam_m_lvid_sx_min').val((0.71 * (w ** 0.315) * 10).toFixed(2));
        $('#echocardio_exam_m_lvid_sx_max').val((1.26 * (w ** 0.315) * 10).toFixed(2));
        $('#echocardio_exam_m_lvpw_sx_min').val((0.48 * (w ** 0.222) * 10).toFixed(2));
        $('#echocardio_exam_m_lvpw_sx_max').val((0.87 * (w ** 0.222) * 10).toFixed(2));
    });
    $('#echocardio_exam_m_ao, #echocardio_exam_m_a_sx').on('change', () => {
        const ao = $('#echocardio_exam_m_ao').val();
        const asx = $('#echocardio_exam_m_a_sx').val();
        $('#echocardio_exam_m_asx_ao').val((ao && asx) ? (asx / ao).toFixed(2) : '');
    });
    $('#echocardio_exam_m_pv, #echocardio_exam_m_pa').on('change', () => {
        const pv = $('#echocardio_exam_m_pv').val();
        const pa = $('#echocardio_exam_m_pa').val();
        $('#echocardio_exam_m_pv_pa').val((pv && pa) ? (pv / pa).toFixed(2) : '');
    });
    $('#echocardio_exam_m_tapse, #echocardio_exam_pet_weight').on('change', () => {
        const v = $('#echocardio_exam_m_tapse').val();
        const w = $('#echocardio_exam_pet_weight').val();
        $('#echocardio_exam_m_tapse_n').val((w && v) ? (v / w ** 0.297).toFixed(2) : '');
    });
    $('#echocardio_exam_m_rpa_sx, #echocardio_exam_m_rpa_dx').on('change', () => {
        const sx = $('#echocardio_exam_m_rpa_sx').val();
        const dx = $('#echocardio_exam_m_rpa_dx').val();
        $('#echocardio_exam_m_rpad').val((sx && dx) ? (((sx - dx) / sx) * 100).toFixed(2) : '');
    });
    $('#echocardio_exam_d2_ao, #echocardio_exam_d2_asx').on('change', () => {
        const ao = $('#echocardio_exam_d2_ao').val();
        const asx = $('#echocardio_exam_d2_asx').val();
        $('#echocardio_exam_d2_asx_ao').val((ao && asx) ? (asx / ao).toFixed(2) : '');
    });
    $('#echocardio_exam_d2_lad_long_axis, #echocardio_exam_pet_weight').on('change', () => {
        const v = $('#echocardio_exam_d2_lad_long_axis').val();
        const w = $('#echocardio_exam_pet_weight').val();
        $('#echocardio_exam_d2_lad_n').val((w && v) ? (v / w ** 0.324).toFixed(2) : '');
    });
    $('#echocardio_exam_m_lvid_dx, #echocardio_exam_m_lvid_sx').on('change', () => {
        const sx = $('#echocardio_exam_m_lvid_sx').val();
        const dx = $('#echocardio_exam_m_lvid_dx').val();
        $('#echocardio_exam_vsx_fa').val((sx && dx) ? (((dx - sx) / dx) * 100).toFixed(2) : '');
        $('#echocardio_exam_vsx_edv').val(dx ? ((7 * (dx / 10) ** 3) / (2.4 + (dx / 10))).toFixed(2) : '').trigger('change');
        $('#echocardio_exam_vsx_esv').val(sx ? ((7 / (2.4 + (sx / 10))) * ((sx / 10) ** 3)).toFixed(2) : '').trigger('change');
    });
    $('#echocardio_exam_m_lvid_dx, #echocardio_exam_pet_weight').on('change', () => {
        const v = $('#echocardio_exam_m_lvid_dx').val();
        const w = $('#echocardio_exam_pet_weight').val();
        $('#echocardio_exam_vsx_lvid_dx_n').val((w && v) ? (v / w ** 0.294).toFixed(2) : '');
    });
    $('#echocardio_exam_m_lvid_sx, #echocardio_exam_pet_weight').on('change', () => {
        const v = $('#echocardio_exam_m_lvid_sx').val();
        const w = $('#echocardio_exam_pet_weight').val();
        $('#echocardio_exam_vsx_lvid_sx_n').val((w && v) ? (v / w ** 0.315).toFixed(2) : '');
    });
    $('#echocardio_exam_vsx_edv, #echocardio_exam_pet_weight').on('change', () => {
        const v = $('#echocardio_exam_vsx_edv').val();
        const w = $('#echocardio_exam_pet_weight').val();
        const bsa = w && ((w ** (2 / 3)) * 0.101);
        $('#echocardio_exam_vsx_edvi').val((bsa && v) ? (v / bsa).toFixed(2) : '');
    });
    $('#echocardio_exam_vsx_esv, #echocardio_exam_pet_weight').on('change', () => {
        const v = $('#echocardio_exam_vsx_esv').val();
        const w = $('#echocardio_exam_pet_weight').val();
        const bsa = w && ((w ** (2 / 3)) * 0.101);
        $('#echocardio_exam_vsx_esvi').val((bsa && v) ? (v / bsa).toFixed(2) : '');
    });
    $('#echocardio_exam_vsx_edv, #echocardio_exam_vsx_esv').on('change', () => {
        const edv = $('#echocardio_exam_vsx_edv').val();
        const esv = $('#echocardio_exam_vsx_esv').val();
        $('#echocardio_exam_vsx_ef').val((edv && esv) ? (((edv - esv) / edv) * 100).toFixed(2) : '');
    });
    $('#echocardio_exam_ao_v_max').on('change', () => {
        const v = $('#echocardio_exam_ao_v_max').val();
        $('#echocardio_exam_ao_grad_max').val(v ? (4 * (v ** 2)).toFixed(2) : '');
    });
    $('#echocardio_exam_ao_v_medium').on('change', () => {
        const v = $('#echocardio_exam_ao_v_medium').val();
        $('#echocardio_exam_ao_grad_medium').val(v ? (4 * (v ** 2)).toFixed(2) : '');
    });
    $('#echocardio_exam_ao_pep, #echocardio_exam_ao_et').on('change', () => {
        const pep = $('#echocardio_exam_ao_pep').val();
        const et = $('#echocardio_exam_ao_et').val();
        $('#echocardio_exam_ao_pep_et').val((pep && et) ? (pep / et).toFixed(2) : '');
    });
    $('#echocardio_exam_pulmonar_v_max').on('change', () => {
        const v = $('#echocardio_exam_pulmonar_v_max').val();
        $('#echocardio_exam_pulmonar_grad_max').val(v ? (4 * (v ** 2)).toFixed(2) : '');
    });
    $('#echocardio_exam_pulmonar_v_medium').on('change', () => {
        const v = $('#echocardio_exam_pulmonar_v_medium').val();
        $('#echocardio_exam_pulmonar_grad_medium').val(v ? (4 * (v ** 2)).toFixed(2) : '');
    });
    $('#echocardio_exam_pulmonar_at, #echocardio_exam_pulmonar_et').on('change', () => {
        const at = $('#echocardio_exam_pulmonar_at').val();
        const et = $('#echocardio_exam_pulmonar_et').val();
        $('#echocardio_exam_pulmonar_at_et').val((at && et) ? (at / et).toFixed(2) : '');
    });
    $('#echocardio_exam_mitral_e_wave, #echocardio_exam_mitral_a_wave').on('change', () => {
        const e = $('#echocardio_exam_mitral_e_wave').val();
        const a = $('#echocardio_exam_mitral_a_wave').val();
        $('#echocardio_exam_mitral_e_a').val((e && a) ? (e / a).toFixed(2) : '');
    });
    $('#echocardio_exam_mitral_e_wave, #echocardio_exam_mitral_ivrt').on('change', () => {
        const e = $('#echocardio_exam_mitral_e_wave').val();
        const ivrt = $('#echocardio_exam_mitral_ivrt').val();
        $('#echocardio_exam_mitral_e_ivrt').val((e && ivrt) ? ((e / ivrt) * 100).toFixed(2) : '');
    });
    $('#echocardio_exam_tricuspid_regurgitation_speed').on('change', () => {
        const v = $('#echocardio_exam_tricuspid_regurgitation_speed').val();
        $('#echocardio_exam_tricuspid_grad_av_dx').val(v ? ((v ** 2) * 4).toFixed(2) : '');
    });
});
