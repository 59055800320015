import {
    create,
    parseCreationOptionsFromJSON,
} from '@github/webauthn-json/browser-ponyfill';

$(window).on('users#new:loaded users#edit:loaded users#create:loaded users#update:loaded', () => {
    $('#user_color').colorpicker();
    $('#user_color').on('change', () => {
        $('#user_color').siblings('.input-group-text').css('background-color', $('#user_color').val());
    }).trigger('change');

    $('#user_country').on('change', () => {
        switch ($('#user_country').val()) {
            case 'IT':
                $('[data-province-select]').html($('[data-province-select]').data('province-select'));
                break;
            default:
                $('[data-province-text]').html($('[data-province-text]').data('province-text'));
        }
    }).trigger('change');

    $('#user_city').autocomplete({
        source: $('#user_city').data('source'),
        minLength: 3,
        select(event, ui) {
            if (ui.item) {
                $('#user_province').val(ui.item.province);
                $('#user_zip').val(ui.item.zip);
            }
        },
    });

    if ('credentials' in navigator) {
        $('#user_webauthn_credentials').removeClass('d-none').addClass('d-print-none').on('ajax:success', (event, data) => {
            data.authenticatorSelection = { userVerification: 'discouraged' }; // bug chrome
            $(event.currentTarget).addClass('d-none');
            $('#webauthn_credentials_waiting').removeClass('d-none');
            create(parseCreationOptionsFromJSON({ publicKey: data })).then((response) => {
                $.post($('#user_webauthn_credentials').attr('href'), { publicKeyCredential: response.toJSON(), authenticity_token: $('meta[name=csrf-token]').attr('content') }, () => {
                    $('#webauthn_credentials_qty').html(parseInt($('#webauthn_credentials_qty').html(), 10) + 1);
                }).fail((jqXHR) => {
                    // eslint-disable-next-line no-alert
                    alert(`Error: ${jqXHR.responseText}`);
                }).always(() => {
                    $('#webauthn_credentials_waiting').addClass('d-none');
                    $('#user_webauthn_credentials').removeClass('d-none');
                });
            });
        });
    }
});
