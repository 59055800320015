import CodiceFiscale from 'codice-fiscale-js';


$(window).on('customers#new:loaded customers#edit:loaded costumers#create:loaded costumers#update:loaded', () => {
    $('#customer_city').autocomplete({
        source: $('#customer_city').data('source'),
        minLength: 3,
        select(event, ui) {
            if (ui.item) {
                $('#customer_province').val(ui.item.province);
                $('#customer_zip').val(ui.item.zip);
            }
        },
    });

    $('#customer_country').on('change', () => {
        switch ($('#customer_country').val()) {
            case 'IT':
                $('[data-province-select]').html($('[data-province-select]').data('province-select'));
                break;
            default:
                $('[data-province-text]').html($('[data-province-text]').data('province-text'));
        }
    }).trigger('change');

    $('#customer_birth_city').autocomplete({
        source: $('#customer_birth_city').data('source'),
        minLength: 3,
        select(event, ui) {
            if (ui.item) {
                $('#customer_birth_city').data('tax-code', ui.item.tax_code);
            }
        },
    });

    $('#customer_contact_source').autocomplete({
        source: $('#customer_contact_source').data('source'),
        minLength: 0,
    }).on('focus', ({ currentTarget }) => {
        $(currentTarget).autocomplete('search');
    });

    $('#calc_tax_code').on('click', ({ currentTarget }) => {
        const name = $('#customer_name').val();
        if (!name) $('#customer_name').addClass('is-invalid');
        const surname = $('#customer_surname').val();
        if (!surname) $('#customer_surname').addClass('is-invalid');
        const sex = $('#customer_sex_male').is(':checked') ? 'M' : 'F';
        const date = new Date($('#customer_birthday').val());
        if (!date) $('#customer_birthday').addClass('is-invalid');
        const taxCodeCity = $('#customer_birth_city').data('tax-code');
        if (!taxCodeCity) $('#customer_birth_city').addClass('is-invalid');
        if (name && surname && sex && date && taxCodeCity) {
            $('#customer_name').removeClass('is-invalid');
            $('#customer_surname').removeClass('is-invalid');
            $('#customer_birthday').removeClass('is-invalid');
            $('#customer_birth_city').removeClass('is-invalid');
            const code = new CodiceFiscale({
                name, surname, gender: sex, day: date.getDate(), month: date.getMonth(), year: date.getFullYear(), birthplace: taxCodeCity,
            });
            $('#customer_tax_code').val(code);
        } else if (!$('#customer_tax_code').hasClass('is-invalid')) {
            $('#customer_tax_code').addClass('is-invalid').append($('<span/>', { class: 'help-block', text: $(currentTarget).data('error') }));
        }
        return false;
    });
});

$(window).on('customers#show:loaded', ({ currentTarget }) => {
    $('a[data-document]').on('click', () => {
        $('#document_modal').load($(currentTarget).data('document'), () => {
            $('#document_modal').modal('show');
            $('#document_body').summernote(window.summernote_config);
            $('button[type="submit"][value="download"]').on('click', () => {
                $('#document_modal').modal('toggle');
            });
        });
        return false;
    });
});
