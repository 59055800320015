$(window).on('serologic_exams#new:loaded serologic_exams#edit:loaded serologic_exams#create:loaded serologic_exams#update:loaded', () => {
    $('a[data-exam-check-box]').on('click', ({ currentTarget }) => {
        const p = $(currentTarget).parent();
        switch ($(currentTarget).data('exam-check-box')) {
            case null:
                $(currentTarget).addClass('d-none');
                p.children("a[data-exam-check-box='neg']").removeClass('d-none');
                p.children('input:hidden').val('neg');
                break;
            case 'neg':
                $(currentTarget).addClass('d-none');
                p.children("a[data-exam-check-box='pos']").removeClass('d-none');
                p.children('input:hidden').val('pos');
                break;
            case 'pos':
                $(currentTarget).addClass('d-none');
                p.children("a[data-exam-check-box='null']").removeClass('d-none');
                p.children('input:hidden').val(null);
                break;
            default:
                break;
        }
        return false;
    });
});
