import { settingNames, settingAmbits } from '../constants.js.erb';

$(window).on('species#new:loaded species#edit:loaded species#create:loaded species#update:loaded', () => {
    function changeNames($ambit) {
        const $settingName = $ambit.closest('tr').find('[id$=_name]');
        $settingName.empty();

        settingAmbits[$ambit.val()].forEach((name) => {
            $settingName.append($('<option>').val(name).html(settingNames[name]));
        });
        $settingName.val($settingName.data('default-value') || settingAmbits[$ambit.val()][0]).selectpicker('refresh');
    }
    $('#settings [id$=_ambit]').each((_i, el) => {
        changeNames($(el));
    });
    $('#settings').on('change', '[id$=_ambit]', ({ currentTarget }) => {
        changeNames($(currentTarget));
    });
    $(document).on('cocoon:after-insert', () => {
        $('#settings tr:last [id$=_ambit]').selectpicker('refresh').trigger('change');
    });
});
