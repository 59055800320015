import { Turbo } from '@hotwired/turbo-rails';
import isMobile from 'ismobilejs';
import introJs from 'intro.js';
import * as bootstrap from 'bootstrap';
import strings from './strings.js.erb';

// UTILS
let debounceTimer;
function debounce(func, timeout = 500) {
    return (...args) => {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

// LOAD
document.addEventListener('turbo:load', () => {
// $(document).ready(function(ev) {
    // SPECIFICS
    const { controller, action } = $('body').data();
    $(window).trigger(`${controller}:loaded`);
    $(window).trigger(`${controller}#${action}:loaded`);
    // GENERICS
    $('input[type=file].form-control').on('change', ({ target }) => {
        const fileName = target.files[0].name;
        $(this).next('.label').addClass('selected').html(fileName);
    });
    const introjs = introJs().setOptions(strings.introjsOptions);
    $('[data-bs-toggle="tooltip"]').tooltip();
    $('.selectpicker').selectpicker();

    function noMobile() {
        if (isMobile.any) $('#print, #print-index, #download, #documents, #document, .no-mobile').hide();
    }
    noMobile();

    // Remote forms
    $("form[data-remote='true']").on('submit', (e) => {
        e.preventDefault();
        const { currentTarget } = e;
        $.ajax({
            type: $(currentTarget).attr('method') || 'post',
            url: $(currentTarget).attr('action'),
            data: Object.fromEntries((new FormData(currentTarget)).entries()),
            beforeSend(xhr) {
                $(currentTarget).trigger('ajax:beforeSend', [xhr]);
            },
        }).done((data, status, xhr) => {
            $(currentTarget).trigger('ajax:success', [data, status, xhr]);
        }).fail((xhr, status, error) => {
            $(currentTarget).trigger('ajax:error', [xhr, status, error]);
        }).always((dataOrXhr, status, xhrOrError) => {
            const xhr = status === 'success' ? xhrOrError : dataOrXhr;
            $(currentTarget).trigger('ajax:complete', [xhr, status]);
        });
    });

    // CSRF Token
    $.ajaxSetup({
        headers: { 'X-CSRF-Token': $('meta[name=csrf-token]').attr('content') },
    });
    $(document).on('ajax:complete', (_event, xhr) => {
        const headerToken = xhr.getResponseHeader('X-CSRF-Token');
        if (headerToken) {
            $('meta[name=csrf-token]').attr('content', headerToken);
            $('input[name=authenticity_token]').val(headerToken);
            $.ajaxSetup({
                headers: { 'X-CSRF-Token': headerToken },
            });
        }
    });

    // Guide
    $('#guide').on('click', () => {
        introjs.start();
        return false;
    });

    // Search & Infinite scroll & Sort
    let loadingScroll = false;
    let jqxhrLoadingRecords = null;
    const loadRecords = (table, options = {}) => {
        if (!table.length || window.location.search.includes('unlimit=')) {
            return null;
        }
        loadingScroll = true;
        if (jqxhrLoadingRecords) jqxhrLoadingRecords.abort();
        const append = options.append || false;
        const print = options.print || false;
        if (!append || print) { table.children('tbody').empty(); }
        const params = { sort: table.data('sort'), direction: table.data('direction') };
        if (append) { params.start_position = table.find('tbody tr').length; }
        if ($('#search-query').val()) { params.search_term = $('#search-query').val(); }
        if (print) { params.unlimit = true; }
        if (table.find('input[data-search]').length) {
            params.filter = {};
            table.find('input[data-search]').each((_i, el) => {
                if ($(el).val()) {
                    params.filter[$(el).data('search')] = $(el).val();
                }
            });
        }
        if (!$('#loading_scroll').length && !print) {
            $("<p class='text-center d-print-none' id='loading_scroll'><i class='fas fa-sync fa-spin fa-2x'></i></p>").insertAfter(table);
        }
        jqxhrLoadingRecords = $.getJSON(table.data('scroll'), params, (data) => {
            let dataCompiledTotal = '';
            data.rows.forEach((element) => {
                const dataCompiled = table.data('template').replace(/{([^}]*)}/g, (prop) => {
                    const key = prop.substr(1, prop.length - 2);
                    return element[key] || '';
                });
                dataCompiledTotal += dataCompiled;
            });
            table.children('tbody')[0].insertAdjacentHTML('beforeend', dataCompiledTotal);
            if (!print) { $('[data-bs-toggle="tooltip"]').tooltip(); }
            if (data.count) { $('[data-rows-count]')[0].innerHTML = data.count; }
            if (print) window.print();
        }).always(() => {
            $('#loading_scroll').remove();
            noMobile();
            loadingScroll = false;
        });
        return params;
    };
    window.loadRecords = loadRecords;
    $(window).on('scroll', () => {
        if ($(window).scrollTop() + window.innerHeight > $(document).height() - 5 && loadingScroll === false) {
            debounce(() => { loadRecords($('table[data-scroll]'), { append: true }); })();
        }
    });
    $('#search-query, table[data-scroll] input[data-search]').on('input', () => {
        debounce(() => {
            const params = loadRecords($('table[data-scroll]'));
            $('#download').data('params', params);
        })();
    });
    $('#download').on('click', ({ currentTarget }) => {
        const params = $(currentTarget).data('params');
        if (params) {
            window.open(`${$(currentTarget).attr('href')}&${$.param(params)}`, '_blank');
            return false;
        }
        return true;
    });
    $('#search-query').parent().on('submit', (e) => {
        e.preventDefault();
    });
    $('table[data-scroll] a[data-sort]').on('click', ({ currentTarget }) => {
        jqxhrLoadingRecords?.abort();
        const table = $(currentTarget).closest('table[data-scroll]');
        if (table.data('sort') === $(currentTarget).data('sort')) {
            table.data('direction', table.data('direction') === 'asc' ? 'desc' : 'asc');
        } else {
            table.data('sort', $(currentTarget).data('sort'));
        }
        table.find('a[data-sort] [data-direction]').hideBT();
        table.find(`a[data-sort="${table.data('sort')}"] [data-direction="${table.data('direction')}"]`).showBT();
        loadRecords(table);
        return false;
    });
    $('table[data-scroll]').each((_i, el) => {
        $(el).find(`a[data-sort="${$(el).data('sort')}"] [data-direction="${$(el).data('direction')}"]`).showBT();
        loadRecords($(el));
    });

    // Print
    $('#print').on('click', () => {
        window.print();
    });
    $('#print-index').on('click', () => {
        loadRecords($('table[data-scroll]'), { print: true });
    });

    if ($('#print_all_container').length) {
        $('#print_all_loading').hide();
        $('#print_all_container').show();
        window.print();
    }

    // Form min_max
    function formMinMax() {
        const result = this.id.match(/(.*?)_(?:min|max)$/);
        const valueId = result ? result[1] : this.id;
        if (!valueId || !$(`#${valueId}`).hasClass('form-control') || $(`#${valueId}`).hasClass('is-invalid')) { return; }
        const value = parseFloat($(`#${valueId}`).val());
        const valueMax = parseFloat($(`#${valueId}_max`).val());
        const valueMin = parseFloat($(`#${valueId}_min`).val());
        if (Number.isNaN(value) || Number.isNaN(valueMax) || Number.isNaN(valueMin) || valueMax < valueMin) {
            $(`#${valueId}`).removeClass('is-invalid').removeClass('is-valid');
            $(`#${valueId}`).parent().removeClass('has-warning').removeClass('has-success');
        } else if (value >= valueMin && value <= valueMax) {
            $(`#${valueId}`).removeClass('is-invalid').addClass('is-valid');
            $(`#${valueId}`).parent().removeClass('has-warning').addClass('has-success');
        } else {
            $(`#${valueId}`).removeClass('is-valid').addClass('is-invalid');
            $(`#${valueId}`).parent().removeClass('has-success').addClass('has-warning');
        }
    }
    $("form[data-min-max='true'] input").each(formMinMax).on('input propertychange paste', formMinMax);

    // Table dblclick
    $('table.table-hover').on('dblclick', 'tr', ({ currentTarget }) => {
        const e = $(currentTarget).find('td:last a:first')[0];
        e?.on('click');
    });

    // Ajax form errors
    $('[data-errors-model]').on('ajax:error', ({ target, currentTarget }, xhr) => {
        if (xhr.responseText) {
            $(target).render_form_errors(JSON.parse(xhr.responseText), $(currentTarget).data('errors-model'));
        } else {
            // eslint-disable-next-line no-alert
            alert(strings.error);
        }
    }).on('ajax:success', ({ target }) => {
        $(target).clear_previous_errors();
    });
});

// Selectpicker & Turbolinks
$(document).on('turbo:before-cache', () => {
    $('.selectpicker').selectpicker('destroy').addClass('selectpicker');
});

/// //////////////
// CONFIGURATIONS
/// //////////////

// Bootstrap: show/hide, enable/disable
$.fn.extend({
    showBT() {
        this.each((_i, el) => {
            if ($(el).hasClass('d-none')) { $(el).removeClass('d-none'); }
            if ($(el).is('input,select')) { $(el).enableBT().parent().showBT(); }
        });
        return this;
    },
    hideBT() {
        this.each((_i, el) => {
            if (!$(el).hasClass('d-none')) { $(el).addClass('d-none'); }
            if ($(el).is('input,select')) { $(el).disableBT().parent().hideBT(); }
        });
        return this;
    },
    toggleBT(show) {
        return show ? this.showBT() : this.hideBT();
    },
    enableBT() {
        this.each((_i, el) => {
            if ($(el).hasClass('disabled')) { $(el).removeClass('disabled'); }
            if ($(el).is('input,select')) { $(el).prop('disabled', false); }
        });
        return this;
    },
    disableBT() {
        this.each((_i, el) => {
            if (!$(el).hasClass('disabled')) { $(el).addClass('disabled'); }
            if ($(el).is('input,select')) { $(el).prop('disabled', true); }
        });
        return this;
    },
});


// Confirm

window.myConfirm = (message) => {
    const element = $(`
        <div class="modal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">${strings.confirm.title}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        <p>${message}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">${strings.confirm.cancel}</button>
                        <button type="button" class="btn btn-primary" id="confirm">${strings.confirm.commit}</button>
                    </div>
                </div>
            </div>
        </div>
    `);
    const myModal = new bootstrap.Modal(element);
    myModal.show();
    return new Promise((resolve) => {
        element.one('hide.bs.modal', () => {
            resolve(false);
        }).one('click', '#confirm', () => {
            element.off('hide.bs.modal');
            myModal.hide();
            resolve(true);
        });
    });
};

Turbo.setConfirmMethod(window.myConfirm);

// Forms
$.fn.extend({
    render_form_errors(errors, model) {
        this.clear_previous_errors();
        $.each(errors, (field, messages) => {
            const $input = $(`[name="${model}[${field}]"], [name="${model}[${field}(1i)]"]`).addClass('is-invalid');
            const errorId = $input.parent().data('errors');
            (errorId ? $(`#${errorId}`).addClass('is-invalid') : $input).append($('<small/>', { class: 'invalid-feedback', text: messages.join(' & ') }));
        });
    },
    clear_previous_errors() {
        $('.is-invalid', this).each((_i, el) => {
            $('.help-block', $(el)).remove();
            $(el).removeClass('is-invalid');
        });
    },
});

// Summernote
window.summernote_config = {
    lang: 'it-IT',
    height: 700,
    toolbar: [
        ['fontsize', ['fontsize']],
        ['style', ['style']],
        ['fontstyle', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['strikethrough', 'superscript', 'subscript']],
        ['fontfamily', ['fontname']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph', 'height']],
        ['insert', ['table', 'hr', 'placeholder']],
        ['misc', ['fullscreen', 'codeview', 'undo', 'redo', 'help']],
    ],
};

/// //////////////
// UTILITIES
/// //////////////

// Backup
window.checkBackup = (path, filename) => {
    if (!filename) return;
    $('#backup').hide();
    $('#backup-working').css('display', 'block');
    const myTimer = setInterval(() => {
        $.getJSON(path, { filename, test: true }, (data) => {
            if (data === true) {
                clearInterval(myTimer);
                $('#backup').css('display', 'block');
                $('#backup-working').hide();
                window.location.href = `${path}?filename=${filename}`;
            }
        });
    }, 5000);
};
